// App.tsx
import React, { useEffect, useState } from 'react';
import './assets/css/style.css'

import Banner from './components/Banner/Banner';
import { LandingPageContent } from './landing-page-content';
import { useSettings } from '@common/core/settings/use-settings';
import Header from './components/headers/Header';
import AboutBlock from './components/AboutBlock/AboutBlock';
import FanfactBlock from './components/FanfactBlock/FanfactBlock';
import VideoBlock from './components/VideoBlock/VideoBlock';
import PortfolioBlock from './components/PortfolioBlock/PortfolioBlock';
import CallToAction from './components/CallToAction/CallToAction';
import TestimonialBlock from './components/TestimonialBlock/TestimonialBlock';
import TeamBlock from './components/TeamBlock/TeamBlock';
import WorkBrandBlock from './components/WorkBrandBlock/WorkBrandBlock';
import { Helmet } from 'react-helmet';
import SectionFooter from './components/Footer/Footer';
import ServicesBlock from './components/ServiceBlock/ServiceBlock';
import Splash from './components/Splash/Splash';
import AOS from 'aos';
import 'aos/dist/aos.css';

export function LandingPage() {
  const [isLoading, setIsLoading] = useState(true);
  const settings = useSettings();
  useEffect(() => {
	
		AOS.init({
			easing: 'ease',
			duration: 1200, // Ajusta la duración si es necesario
			once: true // Asegúrate de que las animaciones solo se ejecuten una vez
		});  }, [])
  const handleFinishLoading = () => {
    // Only finish loading if we have the settings data
    if (settings) {
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Splash onFinishLoading={handleFinishLoading} />
      ) : settings ? (

        <Page />

      ) : null}
    </>
  );
};


function Page() {
  const settings = useSettings();

  const homepage = settings.homepage as { appearance: LandingPageContent };
  console.log(homepage)
  const showPricing =
    homepage.appearance.showPricing && settings.billing.enable;
  const about = homepage.appearance.about;
  const banner = homepage.appearance.banner;
  const fanFac = homepage.appearance.fanFacBlock;
  const video = homepage.appearance.video;
  const portfolio = homepage.appearance.portfolio;
  const service = homepage.appearance.services;
  const callToActions = homepage.appearance.callToActions;
  const work = homepage.appearance.work;
  const footer = homepage.appearance.footer;
  const team = homepage.appearance.team;
  const header = homepage.appearance.header;
  return (
    <div className="page-wrapper">
      <Header prop={header} />
      <Banner banner={banner} />
      <ServicesBlock prop={service} />
      <AboutBlock about={about} />

      {/* <FanfactBlock fanFac={fanFac} /> */}
      <VideoBlock prop={video} />
      <CallToAction prop={callToActions} />
      <PortfolioBlock prop={portfolio} />
      {/* <TestimonialBlock /> */}
      <TeamBlock prop={team} />
      <WorkBrandBlock prop={work} />
      <SectionFooter prop={footer} />
    </div>
  );
};



function Scripts() {
  return (
    <Helmet>
      <script src="./assets/js/jquery.js"></script>
      <script src="./assets/js/bootstrap.min.js" defer></script>
      <script src="./assets/js/popper.min.js" defer></script>
      <script src="./assets/js/plugins.js" defer></script>
      <script src="./assets/js/aos.js" defer></script>
      <script src="./assets/js/imagesloaded.pkgd.min.js" defer></script>
      <script src="./assets/js/simple-scrollbar.min.js" defer></script>
      <script src="./assets/js/background-parallax.js" defer></script>
      <script src="./assets/js/jquery.magnific-popup.min.js" defer></script>
      <script src="./assets/js/theia-sticky-sidebar.min.js" defer></script>
      <script src="./assets/js/ResizeSensor.min.js" defer></script>
      <script src="./assets/js/swiper.min.js" defer></script>
      <script src="./assets/js/isotope.pkgd.min.js" defer></script>
      <script src="./assets/js/packery-mode.pkgd.min.js" defer></script>
      <script src="./assets/js/scrolla.jquery.min.js" defer></script>
      <script src="./assets/js/odometer.min.js" defer></script>
      <script src="./assets/js/parallaxie.min.js" defer></script>
      <script src="./assets/js/isInViewport.jquery.js" defer></script>
      <script src="./assets/js/color-switcher.js" defer></script>
      <script src="./assets/js/jscolor.min.js" defer></script>
      <script src="./assets/js/main.js" defer></script>
    </Helmet>
  );
}
